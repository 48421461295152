import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link as MuiLink,
} from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        py: 3,
        mt: "24px",
        backgroundColor: "rgba(102, 173, 117, 0.7)",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <MuiLink
              component={Link}
              to="/terms"
              color="inherit"
              display="block"
            >
              Общи условия
            </MuiLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiLink
              component={Link}
              to="/about"
              color="inherit"
              display="block"
            >
              За нас
            </MuiLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiLink
              component={Link}
              to="/contact"
              color="inherit"
              display="block"
            >
              Контакти
            </MuiLink>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear()} Arabesk. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
