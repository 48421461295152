import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import axios from "axios";
import { store, persistor } from "./store";
import { Container } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { SET_USER_DATA } from "./actions/types";
import { handleApiError } from "./actions/authActions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import "./App.css";
import backgroundImage from "./img/arabesk.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const History = lazy(() => import("./pages/History"));
const AddPoints = lazy(() => import("./pages/AddPoints"));
const Login = lazy(() => import("./components/Login"));
const Modify = lazy(() => import("./pages/Modify"));
const Register = lazy(() => import("./components/Register"));
const ForgottenPassword = lazy(() => import("./components/ForgottenPassword"));
const NewPassword = lazy(() => import("./components/NewPassword"));
const Privacy = lazy(() => import("./components/Privacy"));
const Contact = lazy(() => import("./components/Contact"));
const About = lazy(() => import("./components/About"));
const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: `url('./img/arabesk.jpg') no-repeat center center fixed`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    padding: spacing(2),
  },
  background: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    // position: 'fixed',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundImage: `url(${backgroundImage})`, // Use the imported background image
    backgroundSize: "cover",
    backgroundPosition: "center",
    // opacity: 0.5, // Adjust the opacity as needed
  },
}));

const App = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const data = {
      token: localStorage.getItem("token"),
      user: {
        username: localStorage.getItem("username"),
        role: localStorage.getItem("role"),
        points: localStorage.getItem("points"),
      },
    };
    if (data?.token && data?.user?.username) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      fetchData(data.user.username, data.token).then((response) => {
        data.user.role = response.data.role;
        data.user.points = response.data.points;
        data.user.totalPoints = response.data.totalPoints;
        dispatch({ type: SET_USER_DATA, payload: data });
      });
    }
  }, [dispatch]);

  const fetchData = async (username, token) => {
    try {
      const res = await axios
        .get(process.env.REACT_APP_API_URL + `/api/users/${username}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((error) => {
          dispatch(handleApiError(error));
        });

      return res;
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className={classes.root}>
            <ToastContainer />
            <Header />
            <Container style={{ flex: 1 }}>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/shop" element={<Shop />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/register" element={<Register />} />
                  <Route
                    exact
                    path="/forgotten_password"
                    element={<ForgottenPassword />}
                  />
                  <Route exact path="/new_password" element={<NewPassword />} />
                  <Route exact path="/terms" element={<Privacy />} />
                  <Route exact path="/about" element={<About />} />
                  <Route exact path="/contact" element={<Contact />} />
                  <Route
                    path="/history"
                    element={
                      <ProtectedRoute requiredRole="client">
                        <History />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/history/:username"
                    element={
                      <ProtectedRoute requiredRole="client">
                        <History />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/modify"
                    element={
                      <ProtectedRoute requiredRole="manager">
                        <Modify />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/addpoints"
                    element={
                      <ProtectedRoute requiredRole="waiter">
                        <AddPoints />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </Suspense>
            </Container>
            <Footer />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
