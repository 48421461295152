import { combineReducers } from 'redux';
import authReducer from './authReducer';
import editReducer from './editReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  edit: editReducer,
  users: userReducer
});

export default rootReducer;