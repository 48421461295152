import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { cyan } from "@mui/material/colors";
import { logout } from "../actions/authActions";
import { useNavigate, Link } from "react-router-dom";
import { Menu as MenuIcon } from "@mui/icons-material";
import logo from "./../img/logo.png"; // Import your image file here
import { spacing } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(102, 173, 117, 0.7)",
    },
    secondary: cyan,
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "transparent",
    boxShadow: "none",
  },
  headerPadding: {
    marginBottom: 120,
  },
  menuButton: {
    marginRight: spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  toolbar: {
    minHeight: "64px", // Adjust based on your design
  },
  list: {
    width: 250,
  },
  logo: {
    height: "60px", // Adjust the height of the logo as needed
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const Logout = () => {
    dispatch(logout());
    navigate("/");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuthenticatedState(true);
      if (user?.role) {
        setRole(user.role);
      }
    }
  }, [isAuthenticatedState, isAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <AppBar position="static" className={classes.headerPadding}>
          <Toolbar className={classes.appBar}>
            <a href="/">
              <img src={logo} alt="Logo" className={classes.logo} />{" "}
              {/* Replace 'Arabesk' with the logo image */}
            </a>
            <Typography variant="h6" className={classes.title} />
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <div
                className={classes.list}
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  <ListItem button component={Link} to="/">
                    <ListItemText primary="Начало" />
                  </ListItem>
                  <ListItem button component={Link} to="/shop">
                    <ListItemText primary="Магазин" id="header-links" />
                  </ListItem>
                  {role === "manager" && isAuthenticated && (
                    <>
                      <ListItem button component={Link} to="/modify">
                        <ListItemText primary="Админ панел" id="header-links" />
                      </ListItem>
                      <ListItem button component={Link} to="/addPoints">
                        <ListItemText primary="Точки" id="header-links" />
                      </ListItem>
                    </>
                  )}
                  {role === "waiter" && isAuthenticated && (
                    <ListItem button component={Link} to="/addPoints">
                      <ListItemText primary="Точки" />
                    </ListItem>
                  )}
                  {role === "client" && isAuthenticated && (
                    <ListItem button component={Link} to="/history">
                      <ListItemText primary="История" />
                    </ListItem>
                  )}
                  {!isAuthenticated && (
                    <ListItem button component={Link} to="/login">
                      <ListItemText primary="Вход" />
                    </ListItem>
                  )}
                  {isAuthenticated && (
                    <ListItem button onClick={() => Logout()}>
                      <ListItemText primary="Изход" />
                    </ListItem>
                  )}
                </List>
              </div>
            </Drawer>
            <Button
              component={Link}
              to="/"
              color="inherit"
              sx={{ display: { md: "block", xs: "none" } }}
              style={{ color: "#fff" }}
            >
              Начало
            </Button>
            <Button
              component={Link}
              to="/shop"
              color="inherit"
              sx={{ display: { md: "block", xs: "none" } }}
              style={{ color: "#fff" }}
            >
              Магазин
            </Button>
            {role === "manager" && isAuthenticated && (
              <>
                <Button
                  component={Link}
                  to="/modify"
                  color="inherit"
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  Админ панел
                </Button>
                <Button
                  component={Link}
                  to="/addPoints"
                  color="inherit"
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  Точки
                </Button>
              </>
            )}
            {role === "waiter" && isAuthenticated && (
              <>
                <Button
                  component={Link}
                  to="/addPoints"
                  color="inherit"
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  Точки
                </Button>
              </>
            )}
            {role === "client" && isAuthenticated && (
              <>
                <Button
                  component={Link}
                  to="/history"
                  color="inherit"
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  История
                </Button>
              </>
            )}

            {!isAuthenticated && (
              <>
                <Button
                  component={Link}
                  to="/login"
                  color="inherit"
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  Вход
                </Button>
              </>
            )}
            {isAuthenticated && (
              <>
                <Button
                  color="inherit"
                  onClick={() => Logout()}
                  sx={{ display: { md: "block", xs: "none" } }}
                  style={{ color: "#fff" }}
                >
                  Изход
                </Button>
              </>
            )}
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              sx={{ display: { md: "none", xs: "block" } }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
};

export default Header;
