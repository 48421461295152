// import axios from 'axios';
import { toast } from "react-toastify";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  FORGOTTEN_PASSWORD,
  FORGOTTEN_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "./types";
import axios from "../axios/axiosInstance";
import { jwtDecode } from "jwt-decode";

export const login = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post("/api/login", { username, password });
    const { token, user } = response.data;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: response.data.token,
        user: response.data.user,
      },
    });

    // Set timeout for token expiration
    const { exp } = jwtDecode(token); // Assuming you have a JWT library like jwt-decode
    const expirationTime = exp * 1000 - Date.now(); // in milliseconds
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data });
    throw error;
  }
};

export const register = (username, email, password) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/register`,
      { username, email, password }
    );
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data.token,
    });
    toast.success("Registration successful!");
  } catch (err) {
    dispatch({ type: REGISTER_FAIL, payload: err.response.data });
    throw err;
  }
};

export const forgotten_password = (email) => async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
      email,
    });
    dispatch({
      type: FORGOTTEN_PASSWORD,
      payload: {
        email: email,
      },
    });
    toast.success("Password reset email sent!");
  } catch (err) {
    dispatch({
      type: FORGOTTEN_PASSWORD_FAIL,
    });
    toast.error("Failed to send password reset email. Please try again.");
  }
};

export const resetPassword =
  (email, resetCode, password) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password`,
        { email, resetCode, password }
      );
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });
      toast.success("Password reset successful!");
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: err.message,
      });
      toast.error("Failed to reset password. Please try again.");
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: LOGOUT });
  toast.success("Logout successful!");
};

export const handleApiError = (error) => (dispatch) => {
  if (error.response && error.response.status === 401) {
    dispatch(logout());
    toast.error("Session expired. Please log in again.");
  }
};
