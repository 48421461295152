// reducers/userReducer.js
import { 
  FETCH_USERS_REQUEST, 
  FETCH_USERS_SUCCESS, 
  FETCH_USERS_FAILURE, 
  DELETE_USER_REQUEST, 
  DELETE_USER_SUCCESS, 
  DELETE_USER_FAILURE,
  EDIT_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_REQUEST
} from '../actions/types';

const initialState = {
  users: {
    data: [],
    pagination: {},
  },
  loading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: {
          pagination: state.users.pagination,
          data: state.users.data.filter(user => user.username !== action.payload)
        },
        error: null
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_USER_SUCCESS:
      const updatedUsers = state.users.data.map(user =>
        user.username === action.payload.user ? { ...user, role: action.payload.role } : user
      );
      return {
        ...state,
        loading: false,
        users: {
          pagination: state.users.pagination,
          data: updatedUsers
        },
        error: null
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
