/* eslint-disable import/no-anonymous-default-export */
import { LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_SUCCESS, REGISTER_FAIL, LOGOUT, SET_USER_DATA, FORGOTTEN_PASSWORD, FORGOTTEN_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('username', payload.user.username);
      localStorage.setItem('role', payload.user.role);
      localStorage.setItem('points', payload.user.points);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        user: payload.user
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('points');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case REGISTER_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    case FORGOTTEN_PASSWORD:
      return {
        ...state,
        email: payload.email,
        isAuthenticated: false,
        loading: false
      };
    case FORGOTTEN_PASSWORD_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetSuccess: true,
        loading: false,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('points');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case SET_USER_DATA: 
      localStorage.setItem('token', payload.token);
      localStorage.setItem('username', payload.user.username);
      localStorage.setItem('role', payload.user.role);
      localStorage.setItem('points', payload.user.points);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        user: payload.user
      };
    default:
      return state;
  }
}