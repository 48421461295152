import axios from 'axios';
import { store } from '../store'; // Your Redux store
import { logout } from './../actions/authActions';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout());
      // Redirect to login page or show a notification
      // history.push('/login');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
