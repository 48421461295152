export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGOUT = 'LOGOUT'; 
export const LOAD_SITE = 'LOAD_SITE';
export const MANAGE_POINTS = 'MANAGE_POINTS';
export const MANAGE_POINTS_FAIL = 'MANAGE_POINTS_FAIL';
export const SET_USER_DATA = 'SET_USER_DATA';
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const FORGOTTEN_PASSWORD_FAIL = 'FORGOTTEN_PASSWORD_FAIL';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';


export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const IS_CLOSED_BANNER = 'IS_CLOSED_BANNER';