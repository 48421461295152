/* eslint-disable import/no-anonymous-default-export */
import { MANAGE_POINTS, MANAGE_POINTS_FAIL } from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MANAGE_POINTS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false
      };
    case MANAGE_POINTS_FAIL:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
}